$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($('body').hasClass('open')){
            $('body').removeClass('open');
            $('header nav .menuwrapper').slideUp();
        }else{
            $('body').addClass('open');
            $('header nav .menuwrapper').slideDown();
        }
    });

    $('#howWeWork .wrapper ol li').append('<div class="triangle"></div>');

    heightJobsHome();
});

//HEIGHT HEADERIMAGE TITLE
function heightJobsHome(){
    $heightJob = $('#jobs .left ul li').outerHeight();
    $heightHotJob = $('#jobs .left ul li .content .hotJob').outerHeight();
    heightJobHalf = $heightJob / 2;
    $heightminMargin = heightJobHalf - 20;

    $("#jobs .right ul").css({'margin-top': $heightHotJob});
    $("#jobs .right ul li").css({'min-height': $heightminMargin});
}

equalheight = function(container){

var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {

   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

$(window).load(function() {
    equalheight('#strengths ul li');
    equalheight('#why .equal');
    equalheight('footer .equal');
    equalheight('#jobs .left ul li .content h4');
    equalheight('#jobs .left ul li .content ul');
    equalheight('#whatWeDo ol li b');
    equalheight('#whatWeDo ol li strong');
});

$(window).resize(function(){
    equalheight('#strengths ul li');
    equalheight('#why .equal');
    equalheight('footer .equal');
    equalheight('#jobs .left ul li .content h4');
    equalheight('#jobs .left ul li .content ul');
    equalheight('#whatWeDo ol li b');
    equalheight('#whatWeDo ol li strong');
    heightJobsHome();
});
